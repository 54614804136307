import React, { FunctionComponent, ReactElement, useEffect, useId, useState } from "react";
import { toast } from "react-toastify";
import ellipsis from "../../assets/3_dots_horizontal.svg";
import blue_tick from "../../assets/blue_tick.svg";
import closeIcon from "../../assets/close-icon.svg";
import closed_indicator from "../../assets/closed-indicator.svg";
import copy_link from "../../assets/copy_link.svg";
import documentChatIcon from "../../assets/document-chat.svg";
import download from "../../assets/download.svg";
import open_indicator from "../../assets/open-indicator.svg";
import reopen from "../../assets/reopen.svg";
import trash from "../../assets/trash.svg";
import Accordion from "../../components/Accordian/Accordion";
import Button from "../../components/Button/Button";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import useWindowSize from "../../customHooks/useWindowSize";
import { sharedDocumentUrl } from "../../endpoints/endpoints";

import { DiscussionItem, IssueReport } from "../../utils/interface";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../customHooks/useUserPermissions";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { useStore } from "../../useStore";
import { downloadInBrowser } from "../../utils/FileUtils";
import { useGet_FileBlob } from "../PartsAndService/QuoteRequestHttpServices";
import { IssueReportApi } from "./IssueReportHttpServices";
import classes from "./IssueReports.module.css";

const IssueReportItem: FunctionComponent<{
  issueReport: IssueReport;
  index: number;
  unread: boolean;
  canDelete: boolean;
  canOpenClose: boolean;
  handleFileView(FileId: number, globalItemId?: number): void;
  setDiscussionItem: React.Dispatch<React.SetStateAction<DiscussionItem | undefined>>;
}> = ({ issueReport, index, unread, setDiscussionItem, canDelete, canOpenClose, handleFileView }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false); // props.index === 0
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [modal, setModal] = useState<ReactElement>();
  const { isDesktop, isTablet, isMobile } = useWindowSize();
  const { selectedPlant, plantConfig, setShowError, header: {data: {user}} } = useStore();
  
  const { hasOnePermission } = usePermissions();
  const navigate = useNavigate();
  const link = `${sharedDocumentUrl}/${issueReport.fileId}`;
  
  const tooltipId = useId().replaceAll(":", "_");
  const refetchData = () => {
    queryClient.invalidateQueries([queryKeys.issueReportItems, plantConfig.data.baseOrgId]);
  };

  useEffect(() => {
    const closeAccordion = () => setIsOpen(false);
    window.addEventListener("resize", closeAccordion);
    return () => window.removeEventListener("resize", closeAccordion);
  }, []);

  const modals = {
    deleteModal: (
      <ModalGlobal modalSize="sm" modaltitle={`Delete Documents`} isModalHeader={true} modalClose={() => setModal(undefined)}>
        <div style={{ textAlign: "justify" }}>
          <p>
            {`Are you sure you want to delete issue report ${issueReport.reportId} from ${selectedPlant.name}? This will also delete the attached discussion board. This cannot be undone.`}
            <br />
            <br />
            {`Please note: deleting this issue report from Customer Portal will not delete it from the TOMONI Dashboard.`}
          </p>
          <div style={{ color: "#666666", marginTop: 20 }}>
            {issueReport.reportId}&nbsp;&nbsp;&nbsp;{issueReport.displayFileName}
          </div>
          <div style={{ marginTop: 40 }} className={classes.flex_row}>
            <Button
              className={`redDelete`}
              disabled={false}
              text={"Yes, Delete"}
              onClick={async () => {
                const { data: success } = await IssueReportApi.delete(issueReport.globalId);
                if (success) {
                  refetchData();
                  setModal(
                    <ModalGlobal
                      modaltitle={`Document Successfully Deleted`}
                      isModalHeader={true}
                      modalClose={() => setModal(undefined)}
                      modalSize="sm"
                      titleClassName="centeredText"
                    >
                      <div>
                        <p>
                          {`You successfully deleted issue report ${issueReport.reportId} (and its attached discussion board) from ${selectedPlant.name}. This did not delete the issue report from the TOMONI Dashboard.`}
                        </p>
                        <div style={{ marginTop: 40, display: "flex", justifyContent: "center" }}>
                          <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={() => setModal(undefined)} />
                        </div>
                      </div>
                    </ModalGlobal>
                  );
                }
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => setModal(undefined)} />
          </div>
        </div>
      </ModalGlobal>
    ),
    closeModal: (
      <ModalGlobal modalSize="sm" modaltitle={`Close Issue Report`} isModalHeader={true} modalClose={() => setModal(undefined)}>
        <div style={{ textAlign: "justify" }}>
          <p>
            {`Are you sure you want to close issue report ${issueReport.reportId}? This will update the status of the issue report to “Closed” in Customer Portal only; it will not update the status in TOMONI Dashboard.`}
            <br />
            <br />
            {`You may Re-open the issue report at any time, if needed.`}
          </p>
          <div style={{ color: "#666666", marginTop: 20 }}>
            {issueReport.reportId}&nbsp;&nbsp;&nbsp;{issueReport.displayFileName}
          </div>
          <div style={{ marginTop: 40 }} className={classes.flex_row}>
            <Button
              className={`redDelete`}
              disabled={false}
              text={"Yes, Close"}
              onClick={async () => {
                const { data: success } = await IssueReportApi.update(issueReport.reportId, "Closed");
                if (success) {
                  refetchData();
                  setModal(modals.closedModal);
                }
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => setModal(undefined)} />
          </div>
        </div>
      </ModalGlobal>
    ),
    closedModal: (
      <ModalGlobal
        modaltitle={`Issue Report Successfully Closed`}
        isModalHeader={true}
        modalClose={() => setModal(undefined)}
        modalSize="sm"
        titleClassName="centeredText"
      >
        <div>
          <p>
            {`You successfully closed issue report ${issueReport.reportId} in Customer Portal. This did not update the status of the issue report in TOMONI Dashboard. If this was a mistake, you may Re-open the issue report at any time.`}
          </p>
          <div style={{ marginTop: 40, display: "flex", justifyContent: "center" }} className={classes.flex_row}>
            <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={() => setModal(undefined)} />
            &nbsp;&nbsp;&nbsp;
            <Button
              className={`whiteBtn`}
              disabled={false}
              text={"Re-open Issue Report"}
              onClick={async () => {
                const { data: success } = await IssueReportApi.update(issueReport.reportId, "Open");
                if (success) {
                  refetchData();
                  setModal(modals.reopenedModal);
                }
              }}
            />
          </div>
        </div>
      </ModalGlobal>
    ),
    reopenModal: (
      <ModalGlobal modalSize="sm" modaltitle={`Re-Open Issue Report`} isModalHeader={true} modalClose={() => setModal(undefined)}>
        <div style={{ textAlign: "justify" }}>
          <p>
            {`Are you sure you want to re-open issue report ${issueReport.reportId}? This will update the status of the issue report back to “Open” in Customer Portal only; it will not update the status in TOMONI Dashboard. Re-opening this issue report will also re-enable its associated discussion board for users with contributor permissions.`}
            <br />
            <br />
            {`You may close the issue report again at any time, if needed.`}
          </p>
          <div style={{ color: "#666666", marginTop: 20 }}>
            {issueReport.reportId}&nbsp;&nbsp;&nbsp;{issueReport.displayFileName}
          </div>
          <div style={{ marginTop: 40 }} className={classes.flex_row}>
            <Button
              className={`redDelete`}
              disabled={false}
              text={"Yes, Re-Open"}
              onClick={async () => {
                const { data: success } = await IssueReportApi.update(issueReport.reportId, "Open");
                if (success) {
                  refetchData();
                  setModal(modals.reopenedModal);
                }
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => setModal(undefined)} />
          </div>
        </div>
      </ModalGlobal>
    ),
    reopenedModal: (
      <ModalGlobal
        modaltitle={`Issue Report Successfully Re-opened`}
        isModalHeader={true}
        modalClose={() => setModal(undefined)}
        modalSize="sm"
        titleClassName="centeredText"
      >
        <div>
          <p>
            {`You successfully re-opened issue report ${issueReport.reportId} in Customer Portal. This did not update the status of the issue report in TOMONI Dashboard. Its associated discussion board has been re-enabled for users with contributor permissions.`}
          </p>
          <div style={{ marginTop: 40, display: "flex", justifyContent: "center" }} className={classes.flex_row}>
            <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={() => setModal(undefined)} />
          </div>
        </div>
      </ModalGlobal>
    ),
    copyLinkModal: (
      <ModalGlobal
        modalSize={'sm'}
        modaltitle={`Copy Link to ${issueReport.displayFileName}`}
        titleClassName="centeredText"
        isModalHeader={true}
        modalClass="!max-w-[500px]"
        modalClose={() => setModal(undefined)}
        useFallbackInitialFocus={true}
      >
        <div className="flex flex-row gap-2 justify-between items-center">
          <input type="text" className="w-2/3 px-[13px] py-[16px] text-black" disabled defaultValue={link} />
          &nbsp;
          <Button
            className={`primaryBtn darkBlue hover:bg-dark-blue-30 focus:bg-dark-blue-30 active:bg-black whitespace-nowrap`}
            preImg={
              <img
                src={copy_link}
                alt=""
                style={{ filter: "invert(100%) sepia(100%) saturate(2%) hue-rotate(357deg) brightness(106%) contrast(101%)" }}
              />
            }
            text="Copy Link"
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast("Link successfully copied");
              setTimeout(() => setModal(undefined), 2000);
            }}
          />
          {false && (
            <div style={{ fontSize: "14px", color: "#00749E", display: "flex", alignItems: "center", textAlign: "left", marginTop: "16px" }}>
              <img src={blue_tick} alt="" /> Link successfully copied
            </div>
          )}
        </div>
      </ModalGlobal>
    ),
  };

  const { refetch: DownloadFile } = useGet_FileBlob(issueReport.fileId, { enabled: false });

  const tooltipActions: tooltipActionItem[] = [
    {
      text: "Download",
      img: download,
      showOnlyOn: ["ItemActions"],
      onClick: () => {
        DownloadFile().then((attachmentBlob) => {
          if (attachmentBlob.data) {
            downloadInBrowser(attachmentBlob.data, `${issueReport.reportId}`);
          }
        });
      },
    },
    {
      text: "Copy Link",
      showOnlyOn: ["ItemActions"],
      hideWhenDeleted: true,
      img: copy_link,
      onClick: () => setModal(modals.copyLinkModal),
    },
  ];

  if (hasOnePermission('IssueReports', ['admin', 'delete']) ) {
    tooltipActions.push({
      text: "Delete",
      showOnlyOn: ["ItemActions"],
      hideWhenDeleted: true,
      img: trash,
      onClick: () => setModal(modals.deleteModal),
    });
  }

  if (hasOnePermission('IssueReports', ['admin']) ) {
    if (issueReport.status === "Open") {
      tooltipActions.push({
        text: "Close",
        showOnlyOn: ["ItemActions"],
        hideWhenDeleted: true,
        img: closeIcon,
        onClick: async () => setModal(modals.closeModal),
      });
    } else {
      tooltipActions.push({
        text: "Re-open",
        showOnlyOn: ["ItemActions"],
        hideWhenDeleted: true,
        img: reopen,
        onClick: async () => setModal(modals.reopenModal),
      });
    }
  }

  const fields = (
    <>
      <td title="ID">
        <div>{issueReport.reportId}</div>
      </td>
      <td title="Description" style={{ fontWeight: `${unread ? "bold" : "inherit"}` }}>
        <div>
          <div
            onClick={() => handleFileView(issueReport.fileId, issueReport.globalId)}
            style={{ color: "#00749E", textDecoration: "none", cursor: "pointer" }}
          >
            {issueReport.displayFileName} {issueReport?.isDeleted && "(Deleted)"}
          </div>
        </div>
      </td>
      <td title="Date Posted">
        <div>{dayjs(issueReport.created).format('MM/DD/YYYY')}</div>
      </td>
      <td title="Priority">
        <div>{issueReport.priorityTitle}</div>
      </td>
      <td title="System">
        <div>{issueReport.system}</div>
      </td>
      <td title="Last Modified">
        <div>{dayjs(issueReport.updated).format('MM/DD/YYYY')}</div>
      </td>
      <td title="Status">
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <div className={`${classes.table_icon}`}>
            <img src={issueReport.status === "Open" ? open_indicator : closed_indicator} alt="" />
          </div>
          {issueReport.status}
        </div>
      </td>
      <td title="Discussion">
        <div
          onClick={() => {
            if (hasOnePermission('DiscussionMessages', ['admin', 'view']) || hasOnePermission('DiscussionThreads', ['admin', 'view']) || user.portalAdmin) {
                setDiscussionItem({
                  id: issueReport.reportId,
                  globalId: issueReport.globalId,
                  description: issueReport.displayFileName,
                  topic: "Issue Report",
                  statusBadge: {
                    label: issueReport.status,
                    color: issueReport.status === "Open" ? `#DC6914` : `#5E8800`,
                  },
                  isLocked: issueReport.isLocked,
                  statusChanges: (issueReport.statusChanges || []),
                  statusTimestamp:
                    issueReport.statusChanges? dayjs(Math.max(...issueReport.statusChanges?.map((s) => dayjs(s.modified).get('ms')))) : undefined
                })
              } else {
                navigate('/forbidden', { replace: true });
              }
            }
          }
          
          style={{ cursor: "pointer", color: "#567783" }}
        >
          <div className={`${classes.table_icon}`}>
            <img src={documentChatIcon} alt="" style={{ filter: "var(--svgFilter567783)" }} />
          </div>
          {issueReport.messageCount}
        </div>
      </td>
      <td>
        <div id={tooltipId} onClick={(e) => setShowContextMenu(!showContextMenu)} style={{ color: "#3C5D69" }}>
          <img src={ellipsis} alt="" style={{ cursor: "pointer" }} />{" "}
          {!isDesktop ? <strong style={{ marginTop: 3, marginLeft: 5 }}>More actions</strong> : <></>}
          <BulkActionTooltip
            isDeleted={issueReport?.isDeleted}
            shown={showContextMenu}
            setShown={setShowContextMenu}
            parentId={tooltipId}
            listItems={tooltipActions}
            data={issueReport}
          />
        </div>
      </td>
    </>
  );
  const Mobilefields = (
    <>
      <div title="ID">
        <div>{issueReport.reportId}</div>
      </div>
      <div title="Description" style={{ fontWeight: `${unread ? "bold" : "inherit"}` }}>
        <div>
          <div
            onClick={() => handleFileView(issueReport.fileId, issueReport.globalId)}
            style={{ color: "#00749E", textDecoration: "none", cursor: "pointer" }}
          >
            {issueReport.displayFileName} {issueReport?.isDeleted && "(Deleted)"}
          </div>
        </div>
      </div>
      <div title="Date Posted">
        <div>{dayjs(issueReport.created).format('MM-DD-YYYY')}</div>
      </div>
      <div title="Priority">
        <div>{issueReport.priorityTitle}</div>
      </div>
      <div title="System">
        <div>{issueReport.system}</div>
      </div>
      <div title="Last Modified">
        <div>{dayjs(issueReport.updated).format('MM-DD-YYYY')}</div>
      </div>
      <div title="Status">
        <div>
          <div className={`${classes.table_icon}`}>
            <img src={issueReport.status === "Open" ? open_indicator : closed_indicator} alt="" />
          </div>
          {issueReport.status}
        </div>
      </div>
      <div title="Discussion">
        <div
          onClick={() => {
              if (hasOnePermission('DiscussionMessages', ['admin', 'view']) || hasOnePermission('DiscussionThreads', ['admin', 'view']) || user.portalAdmin) {
                setDiscussionItem({
                  id: issueReport.reportId,
                  globalId: issueReport.globalId,
                  description: issueReport.displayFileName,
                  topic: "Issue Report",
                  statusBadge: {
                    label: issueReport.status,
                    color: issueReport.status === "Open" ? `#DC6914` : `#5E8800`,
                  },
                  isLocked: issueReport.isLocked,
                  statusChanges: (issueReport.statusChanges || []),
                  statusTimestamp:
                    issueReport.statusChanges? dayjs(Math.max(...issueReport.statusChanges?.map((s) => dayjs(s.modified).get('ms')))) : undefined,
                })
              } else {
                navigate('/forbidden', { replace: true });
              }
            }
          }
          style={{ cursor: "pointer", color: "#567783" }}
        >
          <div className={`${classes.table_icon}`}>
            <img src={documentChatIcon} alt="" style={{ filter: "var(--svgFilter567783)" }} />
          </div>
          {issueReport.messageCount}
        </div>
      </div>
      <div>
        <div id={tooltipId} onClick={(e) => setShowContextMenu(!showContextMenu)} className={`text-dark-blue flex flex-row items-center gap-2 whitespace-nowrap ${classes.moreActions}`}>
          <img src={ellipsis} alt="" style={{ filter: "var(--svgFilterDarkBlueButton)" }} className="cursor-pointer" />{" "}
          {!isDesktop ? <strong className="cursor-pointer">More actions</strong> : <></>}
          <BulkActionTooltip
            isDeleted={issueReport?.isDeleted}
            shown={showContextMenu}
            setShown={setShowContextMenu}
            parentId={tooltipId}
            listItems={tooltipActions}
            data={issueReport}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {isDesktop && (
        <tr
          style={{
            background: `${unread ? "rgba(0, 116, 158, 0.05)" : "inherit"}`,
            color: issueReport.isDeleted ? "var(--secondaryGrey)" : "inherit",
          }}
        >
          {fields}
        </tr>
      )}
      {(isMobile || isTablet) && (
        <Accordion
          isDeleted={issueReport?.isDeleted}
          titleContent={
            issueReport?.isDeleted ? `${issueReport.displayFileName.toString()} (Deleted)` : `${issueReport.displayFileName.toString()}`
          }
          isOpen={isOpen}
          headerStyle={{
            paddingLeft: 24,
            fontSize: 14,
            fontWeight: unread ? 700 : 500,
            marginBottom: "8px",
            background: unread ? "rgba(0, 116, 158, 0.05)" : "#f2f2f2",
          }}
          contentStyle={{}}
          toggleOpen={() => setIsOpen(!isOpen)}
        >
          <div className={`${classes.accordion} ${isTablet || isMobile ? classes.isTablet : ""}`}>{Mobilefields}</div>
        </Accordion>
        
      )}
      {modal}
    </>
  );
};

export default IssueReportItem;
