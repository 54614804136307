import { AxiosError } from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useIsFetching } from "react-query";
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { useSelectedPlantDetails } from "../../containers/PlantInformation/DashboardHttpServices";
import { useGetAllByUnitID_PlantEquipments, useGet_PlantEquipment } from "../../containers/PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData, useGet_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { usePermissions } from "../../customHooks/useUserPermissions";
import useWindowSize from "../../customHooks/useWindowSize";
import { queryKeys } from "../../react-query/queryKeys";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import ImageImports from "../../utils/ImageImports";
import {
  PlantEquipmentDTO,
  PlantEquipmentResponseData,
  PlantEquipmentsDTO,
  PlantSiteDetailsDTO,
  UnitDTO,
  UnitGetAllDTO,
} from "../../utils/interface";
import EquipmentAccordian from "../Accordian/EquipmentAccordian/EquipmentAccordian";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import FormDelete from "../FormsPages/FormDelete/FormDelete";
import FormEquipmentAddEdit from "../FormsPages/FormEquipmentAddEdit/FormEquipmentAddEdit";
import FormNamePlatesAddEdit from "../FormsPages/FormNamePlatesAddEdit/FormNamePlatesAddEdit";
import FormPlantSiteAddEdit from "../FormsPages/FormPlantSiteAddEdit/FormPlantSiteAddEdit";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import FormUnitAddEdit from "../FormsPages/FormUnitAddEdit/FormUnitAddEdit";
import HomeCardLoader from "../HomeCardLoader/HomeCardLoader";
import RelatedLinks from "../RelatedLinks/RelatedLinks";
import PlantSiteTable from "../Tables/PlantSiteTable";
import PlantSiteDataBlock from "./PlantSiteDataBlock/PlantSiteDataBlock";
import "./PlantSiteDetails.css";
import classes from "./PlantSiteDetails.module.css";
import PlantSiteDetailsTop from "./PlantSiteDetailsTop/PlantSiteDetailsTop";

let tableColumns = ["Edit", "Equipment Name", "Equipment ID", "Product Type", "Manufacturer", "Model Name", "Nameplates"];

function PlantOverview({ overview }: OverviewProps) {
  const relatedData = useOutletContext<{
    relatedTitle: string;
    relatedSections: [];
  }>();
  const { hasOnePermission, hasPermission } = usePermissions();
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<number>(0);
  const [, setSelectedOption] = useState<string | null>("");
  const [equipmentApiResponseData, setEquipmentApiResponseData] = useState<PlantEquipmentResponseData | null>(null);

  const { linkout, pencil } = ImageImports;

  const [paramsUnit, setParamsUnit] = useState(0);

  const { isMobile, isTablet, isDesktop, isTabletLandscape } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    selectedPlant,
    isModal,
    setIsModal,
    setNameplate,
    plantEquipmentsAttachment,
    unitTypes,
    setUnitTypes,
    unitGetAll,
    setUnitGetAll,
    setEquipmentTypes,
    unit,
    setUnit,
    setPlantEquipment,
    plantEquipment,
    plantEquipments,
    setPlantEquipments,
    setSelectedPlantDetails,
    selectedPlantDetails,
  } = useStore();
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.plantSiteDetails]);

  const { plantId, plantLoad, ambientTemperatureFahrenheit, ambientTemperatureCelsius, lastOutage, nextPlannedOutage } = selectedPlantDetails?.data;

  useGetAll_UnitData(
    selectedPlant.plantId,
    (data: UnitGetAllDTO) => {
      setUnitGetAll?.(data);
    },
    (error: AxiosError<string>) => {
      setUnitGetAll?.({
        ...unitGetAll,
        data: [],
      });
    }
  );

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useApiPlantEquipments Start
  ///////////////////////////////////////////

  const onSuccessPlantEquipment = (data: PlantEquipmentDTO) => {
    setPlantEquipment?.(data);
  };
  const onErrorPlantEquipment = (error: AxiosError<string>) => {
    setErrorData(error);
  };

  useGet_PlantEquipment(selectedEquipmentId as number, onSuccessPlantEquipment, onErrorPlantEquipment);

  ///////////////////////////////////////////
  // Fetch useApiPlantEquipments End
  ///////////////////////////////////////////

  const { refetch: refetchPlantDetails } = useSelectedPlantDetails((plantSiteDetails: PlantSiteDetailsDTO) => {
    setSelectedPlantDetails?.({
      ...plantSiteDetails,
      data: {
        ...plantSiteDetails.data,
        plantId: selectedPlant.plantId,
      },
    });
  }, setErrorData);

  //{***************************************************************************************************************************}

  //{***************************************************************************************************************************}

  //{***************************************************************************************************************************}

  const deleteSuccess = `${plantEquipment.data.equipmentName} was successfully deleted from the Brunswick County equipment list.
  Would you like to add another piece of equipment at this time?`;

  const openEquipmentsAttachments = (plantEquipmentId: number) => {
    setNameplate?.({ id: plantEquipmentId });
  };

  const addEquipmentsAttachments = () => {
    setIsModal?.({
      ...CloseAllModal,
      isAdd: {
        ...isModal.isAdd,
        isNameplateSamePage: true,
      },
    });
  };
  const openEditModal = (plantEquipmentId: number) => {
    setSelectedEquipmentId(plantEquipmentId);
    setIsModal?.({
      ...CloseAllModal,
      isEdit: {
        ...isModal.isEdit,
        isEquipment: true,
      },
    });
  };

  const openEquipmentDeleteModal = (plantEquipmentId: number) => {
    setSelectedEquipmentId(plantEquipmentId);
    setIsModal?.({
      ...CloseAllModal,
      isDelete: {
        ...isModal.isDelete,
        isEquipment: true,
      },
    });
  };

  const handleAddEquipment = () => {
    setIsModal?.({
      ...CloseAllModal,
      isAdd: {
        ...isModal.isAdd,
        isEquipment: true,
      },
    });
  };

  useEffect(() => {
    if (unitGetAll && unitGetAll.data.length > 0) {
      setParamsUnit(unitGetAll.data[0].unitId);
      const UnitSelectOptions = unitGetAll.data.map((unit) => {
        return {
          id: unit.unitId,
          name: unit.name,
        };
      });

      if (UnitSelectOptions) {
        setUnitTypes?.({ data: [...UnitSelectOptions] });
      }
    } else {
      setUnitTypes?.({ data: [] });

      setUnit?.({
        ...unit,
        data: null,
      });
      setParamsUnit(0);

      setPlantEquipments?.({
        ...plantEquipments,
        data: [],
      });
      setEquipmentTypes?.({ data: [] });
    }
  }, [setUnitTypes, unitGetAll]);

  useEffect(() => {
    const currentParams = searchParams.get("unitId");
    if (currentParams) {
      setParamsUnit(parseInt(currentParams));
    }
  }, [paramsUnit, searchParams]);

  //{***************************************************************************************************************************}

  const onSuccessUnitData = (data: UnitDTO) => {
    setUnit?.(data);
  };

  const onErrorUnitData = (error: AxiosError<string>) => {
    setUnit?.({
      ...unit,
      data: null,
    });
    setErrorData(error);
  };

  useGet_UnitData(paramsUnit, onSuccessUnitData, onErrorUnitData);

  // couldnt test locally - intent is to clear selectedPlantDetails on failure (currently holding prev plant data) -- will remove comment on test confirm
  useEffect(() => {
    if (!selectedPlantDetails?.success && plantId !== selectedPlant.plantId)
      setSelectedPlantDetails?.({
        ...selectedPlantDetails,
        data: {
          plantId: selectedPlant.plantId,
          plantLoad: "",
          ambientTemperatureFahrenheit: "",
          ambientTemperatureCelsius: "",
          lastOutage: undefined,
          nextPlannedOutage: undefined,
        },
      });
  }, [selectedPlant, plantId, selectedPlantDetails, setSelectedPlantDetails]);

  //{***************************************************************************************************************************}

  const onSuccessPlantEquipments = (data: PlantEquipmentsDTO) => {
    setPlantEquipments?.(data);
    const plantEquipmentTypes = data.data.map((equipment) => {
      return {
        id: equipment.plantEquipmentId,
        name: equipment.equipmentName,
      };
    });
    if (plantEquipmentTypes) {
      setEquipmentTypes?.({ data: [...plantEquipmentTypes] });
    }
  };
  const onErrorPlantEquipments = (error: AxiosError<string>) => {
    setPlantEquipments?.({
      ...plantEquipments,
      data: [],
    });
    setEquipmentTypes?.({ data: [] });
    setErrorData(error);
  };
  useGetAllByUnitID_PlantEquipments(paramsUnit, onSuccessPlantEquipments, onErrorPlantEquipments);

  //{***************************************************************************************************************************}

  const handleUnitSelection = (e: React.MouseEvent<HTMLDivElement>, {id: unitId}: {id: number, name: string}) => {
    setParamsUnit(unitId);
    setSearchParams({ unitId: unitId.toString() });
  };

  return (
    <>
      {
        <div>
          {isFetching > 0 ? (
            <div className={overview ? "card card-county plant-detail" : "card card-county plant-detail plant-details-main row-to-col w-88vw"} style={{padding: "0"}}>
              <HomeCardLoader minHeight="180px" showBottom={false}/>
            </div>
          ) : (
            <div className={overview ? "card card-county plant-detail" : "card card-county plant-detail plant-details-main row-to-col w-88vw"}>
              {!overview ? (
                <>
                  <PlantSiteDetailsTop />
                  {unit.data && (
                    <>
                      <hr />
                      <section>
                        <div className={classes.plant_info_header}>
                          <strong>Configuration Details</strong>
                          <p>Plant Arrangement and Unit</p>
                        </div>
                        <div className={classes.unitWrapper}>
                          <div className={`${classes.flex} ${classes.actionwrapper} ${classes.justify_space_between}`}>
                            {/*************     Need to look at this response to no units being returned                          jlh ************************/}
                            {/*************     Causing user to think the function is broken and unresponsive                     jlh ************************/}
                            {/*************     Should return "no Units Avialable not add the "disable" class to the dropdown     jlh ************************/}
                            <div
                              className={
                                unit.data ? `nameplate-dropdown  inline-flex unitDropdown` : `nameplate-dropdown disabled inline-flex unitDropdown`
                              }
                            >
                              <Dropdown
                                className="text-black placeholder-black"
                                placeholderStyle={{color: '#000000 !important'}}
                                dropdownStyle={{color: '#000000 !important'}}
                                isPlantSelection={true}
                                dropdowntype={""}
                                options={unit.data ? unitTypes.data : []}
                                handleDropDownSelect={handleUnitSelection}
                                selectedOption={unit.data ? unit.data.name : "No Units Avialable"}
                                setSelectedOption={setSelectedOption!}
                              />
                            </div>
                            {hasOnePermission(['ThirdPartyEquipment', 'MitsubishiPowerEquipment'], 'add') && isTablet && (
                              <Button
                                className={`darkBlue ${classes.addBtn}`}
                                disabled={!unit.data}
                                text="Add Equipment"
                                onClick={handleAddEquipment}
                              />
                            )}
                          </div>
                        </div>
                      </section>
                      <div className="col-to-row config-details-cta">
                        <div className="row-to-col flex-grow-1" style={{ paddingRight: "24px" }}>
                          <span className={isTabletLandscape || isTablet || isMobile ? "marginBot24" : "marginBot32"}>
                            <PlantSiteDataBlock label={"Notes"} value1={unit.data ? unit.data.notes : "--"} />
                          </span>
                        </div>
                        {hasOnePermission(['ThirdPartyEquipment', 'MitsubishiPowerEquipment'], 'add') && (isDesktop || isMobile) && (
                          <div className="col-to-row  mwidth-206 height36">
                            <Button className="darkBlue" text="Add Equipment" disabled={!unit.data} onClick={handleAddEquipment} />
                          </div>
                        )}
                      </div>

                      <div className="col-to-row config-details config-section-wrapper flex-start marginBot32">
                        <div className="row-to-col config-section">
                          <div className="config-header">Unit Configuration</div>
                          <div className="config-detail">{unit.data ? unit.data.unitConfigurationName : "--"}</div>
                        </div>
                        <div className="row-to-col config-section">
                          <div className="config-header">Output Potential</div>
                          <div className="config-detail">{unit.data ? unit.data.totalMW : "--"}</div>
                        </div>
                        <div className="row-to-col config-section">
                          <div className="config-header">Fuel Type</div>
                          <div className="config-detail">{unit.data ? unit.data.fuelTypeName : "--"}</div>
                        </div>
                        <div className="row-to-col config-section">
                          <div className="config-header">Direct Ownership</div>
                          <div className="config-detail">{unit.data ? unit.data.directOwnership : "--"}</div>
                        </div>
                        <div className="row-to-col config-section">
                          <div className="config-header">Parent/Group Owner</div>
                          <div className="config-detail">{unit.data ? unit.data.parentGroupOwnership : "--"}</div>
                        </div>
                        <div className="row-to-col config-section">
                          <div className="config-header">CO Date</div>
                          <div className="config-detail">{unit.data ? dayjs(unit.data.coDate).format("MM/DD/YYYY") : "--"}</div>
                        </div>
                        {hasPermission('UnitManagement', 'edit') && unit.data && (
                          <>
                            <img
                              src={pencil}
                              className="pencil"
                              alt=""
                              onClick={() => {
                                setIsModal!({
                                  ...CloseAllModal,
                                  isEdit: {
                                    ...isModal.isEdit,
                                    isUnit: true,
                                  },
                                });
                              }}
                            />
                          </>
                        )}
                      </div>

                      {isDesktop ? (
                        <PlantSiteTable
                          tableColumns={tableColumns}
                          tableData={plantEquipments}
                          openEditModal={openEditModal}
                          openEquipmentsAttachments={openEquipmentsAttachments}
                        />
                      ) : (
                        <EquipmentAccordian editRow={openEditModal} panels={plantEquipments} openEquipmentsAttachments={openEquipmentsAttachments} />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="row-to-col">
                    <div className="col-item county-col pad-bottom16">
                      <span className="county">{selectedPlant.name}</span>
                    </div>
                    {overview && (
                      <div className={`${isTablet ? "pad-bottom32" : "pad-bottom24"} `}>
                        <Button
                          className="darkBlue"
                          text="View Plant Details"
                          onClick={() => navigate("/plant-information/plant-site-information")}
                        />
                      </div>
                    )}
                    {!isDesktop && (
                      <div className={`${!!(plantLoad?.length > 0) ? "col-item" : "error_state"} ${isMobile ? "pad-bottom32" : ""}`}>
                        <strong className="col-item-title">TOMONI Hub Monitored Output</strong>
                        <div className="plant-load-mw">
                          <span className={`numeric-value ${!!(plantLoad?.length > 0) ? "value-type" : ""}`}>{!!(plantLoad?.length > 0) ? plantLoad : "Unavailable"}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {isDesktop && (
                    <div className="row-to-col">
                      <div className={!!(plantLoad?.length > 0) ? "col-item" : "error_state"}>
                        <strong className="col-item-title">TOMONI Hub Monitored Output</strong>
                        <div className="plant-load-mw">
                          <span className={`numeric-value ${!!(plantLoad?.length > 0) ? "value-type" : ""}`}>{!!(plantLoad?.length > 0) ? plantLoad : "Unavailable"}</span>
                        </div>
                      </div>

                      <div
                        className={
                          !!(ambientTemperatureCelsius?.length > 0 || ambientTemperatureFahrenheit?.length > 0) ? "col-item" : "error_state"
                        }
                      >
                        <strong className="col-item-title">Ambient Temperature</strong>
                        {ambientTemperatureCelsius?.length > 0 || ambientTemperatureFahrenheit?.length > 0 ? (
                          <div className="temp-container ">
                            <span className="temp padd5">{ambientTemperatureFahrenheit}</span>
                            <span className="padd5">F</span>
                            <span className="padd5">/</span>
                            <span className="temp padd5">{ambientTemperatureCelsius}</span>
                            <span>C</span>
                          </div>
                        ) : (
                          <div className="temp-container">
                            <span className="numeric-value">Unavailable</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={`row-to-col${isMobile ? "pad-bottom32" : ""} `}>
                    {!isDesktop && (
                      <div
                        className={
                          !!(ambientTemperatureCelsius?.length > 0 || ambientTemperatureFahrenheit?.length > 0)
                            ? "col-item card__label_result_wrp font14"
                            : "error_state"
                        }
                      >
                        <strong className="col-item-title">Ambient Temperature</strong>
                        {ambientTemperatureCelsius?.length > 0 || ambientTemperatureFahrenheit?.length > 0 ? (
                          <div className="temp-container pad-bottom24">
                            <span className="temp padd5">{ambientTemperatureFahrenheit}</span>
                            <span className="padd5">F</span>
                            <span className="padd5">/</span>
                            <span className="temp padd5">{ambientTemperatureCelsius}</span>
                            <span>C</span>
                          </div>
                        ) : (
                          <div className="temp-container pad-bottom24">
                            <span className="numeric-value">Unavailable</span>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={lastOutage ? "col-item" : "error_state"} style={{ marginBottom: "1.12rem" }}>
                      <strong className="col-item-title">Last Planned Outage</strong>
                      {lastOutage ? (
                        <span className="lpo date">
                          {String(lastOutage.month).length === 1 ? "0" + lastOutage.month : lastOutage.month}.
                          {String(lastOutage.day).length === 1 ? "0" + lastOutage.day : lastOutage.day}.{lastOutage.year}
                        </span>
                      ) : (
                        <div className="temp-container">
                          <span className="error">Unavailable</span>
                        </div>
                      )}
                    </div>
                    <div className={nextPlannedOutage ? "col-item" : "error_state"} style={{ marginBottom: "1.12rem" }}>
                      <strong className="col-item-title">Next Planned Outage</strong>
                      {nextPlannedOutage ? (
                        <span className="date">
                          {String(nextPlannedOutage.month).length === 1 ? "0" + nextPlannedOutage.month : nextPlannedOutage.month}.
                          {String(nextPlannedOutage.day).length === 1 ? "0" + nextPlannedOutage.day : nextPlannedOutage.day}.
                          {nextPlannedOutage.year}
                        </span>
                      ) : (
                        <div className="temp-container">
                          <span className="error">Unavailable</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row-to-col marginRight48 font14">
                    <a className="text-black no-underline" href={"https://glance.amermhi.com/"} target="_blank" rel="noreferrer noopener">
                      <div className="psdflex-row">
                        <div className="icon-wrapper">
                          <img src={linkout} className="plt-icon" alt="" />
                        </div>

                        <div>
                          <strong>Go to Glance</strong>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              )}

              {/* MODALS START */}
              {isModal.isEdit.isUnit && <FormUnitAddEdit modaltitle={`Edit Unit Details for ${unit.data?.name}`} modaltitlename={""} isEdit={true} />}
              {isModal.isEditedSuccess.isUnit && (
                <FormSuccess
                  comment={`The unit details for ${unit.data?.name} plant have been successfully updated.`}
                  modalSize={"sm"}
                  buttonName={"???"}
                  button_addmore={false}
                  button_done={true}
                  modaltitle={`Changes Saved`}
                  isTitleCenter={true}
                  modaltitlename=""
                />
              )}
              {isModal.isEdit.isPlantConfig && (
                <FormPlantSiteAddEdit isEdit={true} modaltitle="Edit Plant Details for " modaltitlename={selectedPlant.name} />
              )}
              {isModal.isEditedSuccess.isPlantConfig && (
                <FormSuccess
                  comment={`The plant details for "${selectedPlant.name}" plant have been successfully updated.`}
                  modalSize={"sm"}
                  buttonName={"???"}
                  button_addmore={false}
                  button_done={true}
                  modaltitle={`Changes Saved`}
                  modaltitlename=""
                />
              )}
              {isModal.isAdd.isEquipment && (
                <FormEquipmentAddEdit
                  modaltitle="Add New Equipment"
                  modaltitlename=""
                  isEdit={false}
                  setEquipmentApiResponseData={setEquipmentApiResponseData}
                />
              )}
              {isModal.isEdit.isEquipment && (
                <FormEquipmentAddEdit
                  isEdit={true}
                  selectedEquipmentId={selectedEquipmentId!}
                  openEquipmentDeleteModal={openEquipmentDeleteModal}
                  modaltitle="Edit Equipment"
                  modaltitlename=""
                  setEquipmentApiResponseData={setEquipmentApiResponseData}
                />
              )}
              {isModal.isDelete.isEquipment && (
                <FormDelete
                  selectedEquipmentId={selectedEquipmentId}
                  modaltitle={`Delete "${plantEquipment.data.equipmentName}" Equipment?`}
                  modaltitlename=""
                />
              )}
              {isModal.isDeleteSuccess.isEquipment && (
                <FormSuccess
                  comment={deleteSuccess}
                  modalSize={"md"}
                  buttonName={"Add Another Equipment"}
                  onClick={handleAddEquipment}
                  button_addmore={true}
                  button_done={true}
                  modaltitle={`"${plantEquipment.data.equipmentName}" Equipment Successfully Deleted`}
                  modaltitlename=""
                />
              )}
              {isModal.isAddedSuccess.isEquipment && (
                <FormSuccess
                  comment={`"${equipmentApiResponseData?.data.equipmentName.toUpperCase()}" was successfully added to the ${
                    selectedPlant.name
                  } equipment list. Would you like to add an attachment to this new piece of equipment?`}
                  modalSize={"sm"}
                  buttonName={"Add Attachment"}
                  button_addmore={true}
                  button_done={true}
                  modaltitle={`Equipment Successfully Added`}
                  modaltitlename=""
                  onClick={addEquipmentsAttachments}
                />
              )}
              {isModal.isEditedSuccess.isEquipment && (
                <FormSuccess
                  comment={`The equipment details for "${equipmentApiResponseData?.data.equipmentName.toUpperCase()}" have been successfully updated. Would you like to review the attachments for this piece of equipment?`}
                  button_addmore={true}
                  button_done={true}
                  buttonName={"View Attachments"}
                  modalSize={"md"}
                  modaltitle={`Changes Saved`}
                  modaltitlename=""
                  onClick={() => {
                    setIsModal?.({
                      ...CloseAllModal,
                    });

                    navigate(`/plant-information/equipmentAttachments/${equipmentApiResponseData?.data.plantEquipmentId}`);
                  }}
                />
              )}

              {isModal.isAdd.isNameplateSamePage && (
                <FormNamePlatesAddEdit
                  isEdit={false}
                  attachmentID={equipmentApiResponseData?.data.plantEquipmentId!}
                  modaltitle={`Add a Nameplate Asset`}
                />
              )}
              {isModal.isAddedSuccess.isNameplateSamePage && (
                <FormSuccess
                  comment={`“${plantEquipmentsAttachment.data.name}” was successfully added to the ${plantEquipmentsAttachment.data.equipmentName} equipment attachments.`}
                  modalSize={"sm"}
                  button_addmore={false}
                  button_done={true}
                  modaltitle={`Nameplate Asset Successfully Added`}
                  modaltitlename=""
                />
              )}
              {/* MODALS  END */}
            </div>
          )}
        </div>
      }
      {relatedData && <RelatedLinks data={relatedData} />}
    </>
  );
}
interface OverviewProps {
  overview: boolean;
}
export default PlantOverview;
